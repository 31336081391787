/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
  Grid,
  Paper,
  Divider,
  DialogContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  Tab,
  Tabs,
  Box,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArchiveIcon from '@material-ui/icons/Archive'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router'
import { format } from 'date-fns'

import AsyncRequest from '../../components/AsyncRequest'
import styles from '../../../resources/theme/global'
import {
  applyDiscount,
  duplicateTime,
  renderRoute,
  truncateNumber,
  dateFormatedFullDate,
} from '../../../util/utils'
import { findClient, archive, fetchAttendanceInit } from '../AttendanceActions'
import Form from '../../clients/pages/Form'
import SelectField from '../../components/SelectField'
import { SNACKBAR } from '../../main/MainActions'
import consts from '../../../util/consts'

import Modal from './ModalGetInfos'
import TotalTable from './TotalTable'
import TableServices from './TableServices'
import Calendar from './Calendar'
import Historic from './Historic'
import DeliveryAddress from './DeliveryAddress'
import ModalShowOpenedServices from './ModalShowOpenedServices'
import AutoComplete from '../../components/Autocomplete'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function Attendance(props) {
  const { classes, onClose, data, path } = props

  const initialDescription = `Observações Gerais:

Formas de Pagamento: `

  const [client, setClient] = useState(null)
  const [currentService, setCurrentService] = useState()
  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState({})
  const [canCorrectFinalValue, setCanCorrectFinalValue] = useState(false)
  const [generalFinalAltered, setGeneralFinalAltered] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [form, setForm] = useState({
    services: [],
    client: [],
    general_discount: '',
    final_value_discount: 0,
    status: '',
    know_way: '',
    general_observations: initialDescription,
    dirty: false,
    scheduling_information: '',
    supplier: '',
    deliveryAddress: [],
    returnAddress: [],
    tags: [],
  })
  const dispatch = useDispatch()
  const services = useSelector(state => state.attendance.services)
  const comoConheci = useSelector(state => state.attendance.comoConheci)
  const permissions = useSelector(state => state.auth.permissions)
  const tags = useSelector(state => state.attendance.tags)

  const confirm = useConfirm()
  const history = useHistory()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const changeOptionType = (index, id) => {
    const items = [...form.services]

    items[index].options = { ...items[index].options, type: id }

    setForm({
      ...form,
      services: items,
    })
  }

  const updateField = useCallback(
    (name, value) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const updateFunction = useCallback(
    (areas, array) => {
      setForm({
        ...form,
        [array]: areas.sort((a, b) => a.name.localeCompare(b.name)),
      })
    },
    [form]
  )

  const updateClient = (name, value) => {
    setClient(value)
  }

  useEffect(() => {
    if (path === 'new') setForm({ ...form, dirty: true })
    else setCanCorrectFinalValue(true)
  }, [path])

  useEffect(() => {
    if (data && !form.dirty) {
      setForm({ ...form, ...data, dirty: true })
    }
  }, [])

  const calculateEachRow = (services, discount) => {
    if (form.dirty === true) {
      services.forEach(item => {
        item.final_value = truncateNumber(
          parseFloat(
            applyDiscount(
              item.default_value * item.quantity,
              discount
            ).toPrecision(10),
            10
          )
        )
      })
    }

    return services
  }

  const returnFinalValue = () => {
    if (form.dirty === true) {
      const generalFinalValue = form.services.reduce((total, item) => {
        let withDiscount = 0
        if (item.final_value) {
          withDiscount = truncateNumber(item.final_value)
        } else {
          withDiscount = truncateNumber(
            applyDiscount(
              item.default_value * item.quantity,
              form.general_discount || item.discount
            )
          )
        }

        return parseFloat(total) + parseFloat(withDiscount)
      }, 0)
      return generalFinalValue
    }
  }

  const correctFinalValue = () => {
    if (form.dirty === true) {
      const generalFinalValue = returnFinalValue()

      const diff =
        Math.round((form.final_value_discount - generalFinalValue) * 100) / 100

      const newServices = [...form.services]

      if (diff !== 0) {
        const position = newServices.length - 1
        newServices[position] = {
          ...newServices[position],
          final_value: (
            Number(newServices[position]?.final_value) + Number(diff)
          ).toFixed(2),
        }

        setForm({
          ...form,
          services: newServices,
        })
      }
    }
  }

  const updateFinalValue = () => {
    if (form.dirty === true) {
      let newServices = [...form.services]

      if (form.general_discount > 0 || generalFinalAltered) {
        newServices.forEach(item => {
          item.discount = form.general_discount
        })

        newServices = calculateEachRow(newServices, form.general_discount)
      }

      setForm({
        ...form,
        final_value_discount: returnFinalValue(),
        services: newServices,
      })

      if (canCorrectFinalValue) {
        correctFinalValue()
        setCanCorrectFinalValue(false)
      }
    }
  }

  useEffect(() => {
    let mounted = true
    if (currentService && mounted) {
      const newService = {
        ...currentService,
        quantity: 1,
        discount: '',
        technician_default: currentService.technician_value,
        final_time: currentService.execution_time,
        default_value_default: currentService.default_value,
        options: { type: 1, base: 0, altura: 0, diametro: 0 },
        is_rework: 0,
        tec_commission: '',
      }

      if (currentService.measureType.id === 2) {
        setOpen(true)
        setCurrentIndex(form.services.length)
      }

      const plusFinal = applyDiscount(
        newService.default_value,
        form.general_discount
      )
      newService.final_value = plusFinal
      newService.discount = form.general_discount
      newService.dateTime = Date.now()
      setForm({
        ...form,
        services: [...form.services, newService],
        final_value_discount: parseFloat(form.final_value_discount) + plusFinal,
      })
    }
    return () => (mounted = false)
  }, [currentService])

  const noDiscount = useMemo(() => {
    if (form.dirty === true) {
      return form.services.reduce((total, item) => {
        const noDiscountValue = item.default_value * item.quantity
        return parseFloat(total) + parseFloat(noDiscountValue)
      }, 0)
    }
  }, [form.services])

  useEffect(() => {
    let mounted = true
    if (mounted && form.dirty === true) {
      setForm({
        ...form,
        total_without_discount: noDiscount,
      })
    }
    return () => (mounted = false)
  }, [noDiscount])

  const removeChildren = index => {
    let minusFinal = 0
    minusFinal = truncateNumber(
      applyDiscount(
        form.services[index].default_value * form.services[index].quantity,
        form.general_discount
      )
    )

    const items = form.services.filter((_, key) => key !== index)

    setForm({
      ...form,
      services: items,
      final_value_discount: form.final_value_discount - minusFinal,
    })
  }

  const updateFinalTime = (index, newValue) => {
    const items = [...form.services]

    items[index] = { ...items[index], final_time: newValue }

    setForm({
      ...form,
      services: items,
    })
  }

  const onChangeFinalValue = () => {
    setCanCorrectFinalValue(true)
    const discount = noDiscount - form.final_value_discount

    const percent = Math.trunc(((discount * 100) / noDiscount) * 100) / 100

    if (percent <= 0) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'O desconto deve ser um valor maior do que zero' },
      })

      return
    }

    setForm({
      ...form,
      general_discount: percent,
    })
  }

  const updateChildren = (index, newValue, propName, json = false) => {
    const items = [...form.services]
    let final = 0

    if (json) {
      items[index].options = {
        ...items[index].options,
        [propName]: newValue,
      }
    } else {
      items[index][propName] = newValue
    }

    let generalFinal = form.final_value_discount

    if (propName === 'discount' || propName === 'quantity') {
      if (form.general_discount) {
        if (items[index].measureType.id === 2) {
          final =
            Math.round(
              (items[index].base || 1) * (items[index].altura || 1) * 100
            ) / 100
          items[index].final_value = applyDiscount(
            final * items[index].default_value,
            items[index].discount
          )

          items[index].quantity = final
        } else {
          items[index].final_value = applyDiscount(
            items[index].default_value * items[index].quantity,
            items[index].discount
          )
        }
      } else if (items[index].measureType.id === 2) {
        items[index].final_value = applyDiscount(
          items[index].quantity * items[index].default_value,
          items[index].discount
        )
      } else {
        const value = applyDiscount(
          items[index].default_value,
          items[index].discount
        )

        if (value >= 0) {
          items[index].final_value = value * items[index].quantity
        } else {
          items[index].final_value =
            items[index].default_value * items[index].quantity
        }
      }

      if (propName === 'quantity') {
        items[index].final_time = duplicateTime(
          [items[index].execution_time],
          items[index].quantity
        )

        items[index].technician_value =
          items[index].technician_default * items[index].quantity
      }

      generalFinal = returnFinalValue(items)
    } else if (propName === 'base' || propName === 'altura') {
      if (propName === 'base') {
        final =
          Math.round(newValue * (items[index].options.altura || 1) * 100) / 100
        items[index].final_value = applyDiscount(
          final * items[index].default_value,
          items[index].discount
        )
      } else {
        final =
          Math.round(newValue * (items[index].options.base || 1) * 100) / 100
        items[index].final_value = applyDiscount(
          final * items[index].default_value,
          items[index].discount
        )
      }

      items[index].quantity = final

      items[index].technician_value =
        items[index].technician_default * items[index].quantity

      generalFinal = returnFinalValue(items)
    } else if (propName === 'diametro') {
      final =
        Math.round(((Math.PI * items[index].options.diametro ** 2) / 4) * 100) /
        100

      items[index].final_value = truncateNumber(
        applyDiscount(final * items[index].default_value, items[index].discount)
      )

      items[index].quantity = final

      items[index].technician_value =
        items[index].technician_default * items[index].quantity
      generalFinal = returnFinalValue(items)
    } else if (propName === 'technician_value') {
      items[index][propName] = newValue
    } else if (propName === 'default_value') {
      items[index][propName] = newValue

      items[index].final_value = applyDiscount(
        items[index].quantity * newValue,
        items[index].discount || 0
      )

      generalFinal = returnFinalValue(items)
    } else if (propName === 'is_rework') {
      if (items[index][propName]) {
        items[index].default_value = 0
      } else {
        items[index].default_value =
          items[index].serviceType?.default_value ||
          items[index].default_value_default
      }

      items[index].final_value = applyDiscount(
        items[index].default_value * items[index].quantity,
        items[index].discount || 0
      )

      generalFinal = returnFinalValue(items)
    } else if (propName === 'bar_code') {
      items[index].bar_code = newValue
    }

    setForm({
      ...form,
      services: items,
      final_value_discount: generalFinal,
    })
  }

  const updateFieldSelect = (name, value, key) => {
    const items = [...form.services]

    items[key].status = value

    setForm({ ...form, services: items })
  }

  const updateFieldSelectSuppliers = (name, value, key) => {
    const items = [...form.services]
    items[key].supplier = value

    setForm({ ...form, services: items })
  }

  const openEdit = useCallback(index => {
    setCurrentIndex(index)
    setOpen(true)
  }, [])

  useEffect(() => {
    if (form.dirty === true) {
      updateFinalValue()
    }
  }, [form.general_discount])

  const initClient = async () => {
    try {
      const resp = await dispatch(findClient(client.id))
      const { data } = resp
      setForm({ ...form, client: data })
    } catch (e) {
      console.error(e)
    }
  }

  const confirmArchive = async id => {
    confirm({
      description: 'Deseja arquivar este orçamento?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(archive(id))
      if (resp.status === 200) {
        await dispatch(fetchAttendanceInit())
        history.push('/attendance')
      }
    })
  }

  const onSubmit = (client, redirect = false) => {
    const { onSubmit } = props

    const {
      services,
      general_discount,
      final_value_discount,
      total_without_discount,
      status,
    } = form
    const statusId = () => {
      if (form?.id && redirect && parseInt(status?.id, 2) === 1) return 2
      if (!form.id && redirect) return 2
      if (!form.id && !redirect) return 1

      return status.id
    }

    const newValues = {
      ...form,
      client: {
        ...client,
        birthday: client.birthday
          ? format(new Date(client.birthday), 'yyyy-MM-dd')
          : null,
      },
      services,
      discount: {
        general_discount,
        final_value_discount,
      },
      total: {
        total_without_discount,
      },
      status: statusId(),
      redirect,
    }

    onSubmit && onSubmit(newValues, redirect)
  }

  const fillNewServices = service => {
    const newServices = {
      ...service,
      final_value_discount: service.total_with_discount,
      services: service.services.map(_item => {
        let item = { ..._item }
        if (item?.measureType.id === 1)
          item = { ...item, quantity: parseInt(item.quantity, 10) }
        item = {
          ...item,
          id: item.serviceType.id,
          technician_default: item.serviceType.technician_value,
          status: null,
          dateTime: Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000,
          bar_code: null,
        }
        return item
      }),
    }

    setForm({
      ...form,
      ...newServices,
      status: '',
      id: null,
    })
  }

  const updateDeliveryAddress = (name, field, value) => {
    const newData = [...form[name]]
    newData[0][field] = value

    setForm({
      ...form,
      [name]: newData,
    })
  }

  const updateDeliveryCep = (field, address) => {
    const newData = [...form[field]]
    newData[0].neighborhood = address?.bairro
    newData[0].city = address?.cidade
    newData[0].uf = address?.uf
    newData[0].street = address?.logradouro

    setForm({
      ...form,
      [field]: newData,
    })
  }

  const handleClickAddOrRemoveAddress = name => {
    if (form[name].length > 0) {
      setForm({
        ...form,
        [name]: [],
      })
    } else {
      setForm({
        ...form,
        [name]: [
          ...form[name],
          {
            cep: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            uf: '',
          },
        ],
      })
    }
  }

  return (
    <>
      {form.client?.opened_services && (
        <ModalShowOpenedServices openedServices={form.client.opened_services} />
      )}
      <Modal
        open={open}
        handleClose={handleClose}
        classes={classes}
        currentIndex={currentIndex}
        services={form.services}
        updateChildren={updateChildren}
        changeOptionType={changeOptionType}
      />
      <DialogContent>
        <Grid
          container
          spacing={1}
          style={
            [consts.ARQUIVADO, consts.CANCELADO].includes(form.status?.id) ||
            form.can_edit === false
              ? { backgroundColor: '#ccc', opacity: 0.5, pointerEvents: 'none' }
              : {}
          }
        >
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={4}>
              {form.id && (
                <Grid container>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.flex}
                    >
                      {`Atendente: ${
                        form.user?.name
                      } - Data/Hora: ${dateFormatedFullDate(form?.created_at)}`}
                    </Typography>
                    {form?.canceled_user && (
                      <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.flex}
                      >
                        {`Cancelado por: ${
                          form.canceled_user?.name
                        } - Data/Hora: ${dateFormatedFullDate(
                          form?.canceled_at
                        )}`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <AsyncRequest
                    label="Telefone ou nome do cliente"
                    link="client/find-by-name"
                    setForm={updateClient}
                    data={client}
                    name="client"
                  />
                </Grid>

                <Grid item xs={1}>
                  <Button
                    color="primary"
                    onClick={initClient}
                    disabled={Boolean(!client?.id)}
                  >
                    Buscar
                  </Button>
                </Grid>

                {renderRoute(['read-schedule'], permissions) && (
                  <Grid item xs={1}>
                    <Calendar classes={classes} />
                  </Grid>
                )}

                {renderRoute(['archive-attendances'], permissions) &&
                  form.status?.id === consts.ORCAMENTO && (
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="calendar"
                        color="primary"
                        onClick={() => confirmArchive(form.id)}
                      >
                        <ArchiveIcon />
                      </IconButton>
                    </Grid>
                  )}

                <Grid item xs={1}>
                  <Historic
                    disabled={!form.client?.id}
                    clientId={form.client?.id}
                    canClick={form.id}
                    fillNewServices={fillNewServices}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Tabs
                    value={currentTab}
                    onChange={(_, value) => setCurrentTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabsheader}
                    centered
                  >
                    <Tab label="Dados Gerais" {...a11yProps(0)} />
                    <Tab label="Endereço de coleta" {...a11yProps(1)} />
                    <Tab label="Endereço de entrega" {...a11yProps(2)} />
                  </Tabs>
                </Grid>
              </Grid>

              <TabPanel value={currentTab} index={0}>
                <Form
                  data={form.client}
                  notShowPost
                  onSubmit={onSubmit}
                  onClose={onClose}
                >
                  <Grid container style={{ padding: 20 }} justify="center">
                    <Grid container justify="center">
                      <Grid xs={12} justify="center">
                        <SelectField
                          style={{ width: '100%' }}
                          options={comoConheci}
                          setForm={updateField}
                          data={form.know_way || null}
                          name="know_way"
                          label="Como soube da Empresa?"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <AutoComplete
                        options={tags}
                        label="Tag"
                        data={form.tags || null}
                        updateFunction={updateFunction}
                        name="tags"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Divider variant="fullWidth" />
                      <Paper
                        className={classes.paper}
                        style={{ backgroundColor: '#f6f6f6', padding: 20 }}
                        elevation={2}
                      >
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justify="space-evenly"
                          alignItems="center"
                        >
                          <Grid item xs>
                            <SelectField
                              style={{ color: 'black', marginTop: '0px' }}
                              options={services}
                              setForm={setCurrentService}
                              data={currentService || null}
                              uniqueState
                              label="Buscar Serviço"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <TableServices
                        items={form.services}
                        classes={classes}
                        removeChildren={removeChildren}
                        updateChildren={updateChildren}
                        discountDisabled={form.general_discount}
                        openEdit={openEdit}
                        updateFinalTime={updateFinalTime}
                        formId={form.id}
                        updateFieldSelect={updateFieldSelect}
                        updateFieldSelectSuppliers={updateFieldSelectSuppliers}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TotalTable
                        noDiscount={noDiscount}
                        setForm={setForm}
                        form={form}
                        classes={classes}
                        onChangeFinalValue={onChangeFinalValue}
                        setGeneralFinalAltered={setGeneralFinalAltered}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="scheduling-comments-content"
                          id="scheduling-comments-header"
                        >
                          <Typography className={classes.heading}>
                            Comentários do agendamento
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            placeholder="Comentários do Agendamento"
                            value={form.scheduling_information}
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            rowsMax={10}
                            onChange={e =>
                              updateField(
                                'scheduling_information',
                                e.target.value
                              )
                            }
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="attendant_commnets-content"
                          id="attendant_commnets-header"
                        >
                          <Typography className={classes.heading}>
                            Comentários do Técnico
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            placeholder="Comentários do Técnico"
                            value={form.attendant_comments}
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            rowsMax={10}
                            disabled
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="payment-comments-content"
                          id="payment-comments-header"
                        >
                          <Typography className={classes.heading}>
                            Comentários do pagamento
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            placeholder="Comentários do Pagamento"
                            value={form.payment_comments}
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={5}
                            rowsMax={10}
                            disabled
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Form>
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                <DeliveryAddress
                  name="deliveryAddress"
                  address={form.deliveryAddress?.[0]}
                  updateField={updateDeliveryAddress}
                  updateDeliveryCep={updateDeliveryCep}
                  handleClick={handleClickAddOrRemoveAddress}
                />
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <DeliveryAddress
                  name="returnAddress"
                  address={form.returnAddress?.[0]}
                  updateField={updateDeliveryAddress}
                  updateDeliveryCep={updateDeliveryCep}
                  handleClick={handleClickAddOrRemoveAddress}
                />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

export default withStyles(styles)(Attendance)
